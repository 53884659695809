import { HostListener, Component, OnInit, Input, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import showdown from "showdown/dist/showdown.js";
import { Router } from '@angular/router';


import { AppDataService } from 'src/app/services/app-data.service';
import { SubstitutionService } from 'src/app/services/substitution.service';


@Component({
  selector: 'app-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss']
  //host: { "(document:click)": "onDocumentClicked($event)" }  // when using this method, the event fires once for each time the component gets loaded.
})


export class MarkdownComponent implements OnInit {
  private module: string = "markdown.component.ts/";
  private markdownConverter: any;

  public safeText: any = ``;
  public _id: string = "";
  public triggerCount: number = Math.random() * 1000; 
  public markdown: string;

  // This @HostListener and function listen for a click event in a dynamic content page. It filters out
  // and ignores clicks on anything except buttons with a 'btn' id that were added in substitution.service.
  @HostListener('click', ['$event']) // '$event.target' // could just send the event.target // https://angular.io/api/core/HostListener
  onClick(e) {
    let func: string = "onClick";
    var exprStart: RegExp;
    var result: any;
    var pageid: string;
    
    if (["ion-button", "button"].includes( e.target.localName) && e.target.id.includes("btn") ) {
      this.appData.log(`${this.module}${func} id="${e.target.id}" outerText="${e.target.outerText}" `); // outerHTML: ${e.target.outerHTML}
      //this.appData.log(`outerHTML: ${e.target.outerHTML}`)

      exprStart = new RegExp('routerlink="home/[a-z0-9]+"', "i"); // check if this button references a page (substitution/links).
      result =  exprStart.exec(e.target.outerHTML);
      //this.appData.log(result);
      if (result) {
        pageid = result[0].replace('routerlink="home/', "").replace('"', "");
        this.appData.log(`page id: ${pageid}`);
        this.router.navigate(["learn", pageid], { skipLocationChange: true });
      } else {
        alert(`'${e.target.outerText}' clicked.`);
      }

    }
  }
  
  @Input() set content(c: string) {
      this.markdown = c;
  };

  @Input()
  set article_id(_id: string) {
    let func = "@Input/set article_id";
    
    this._id = (_id || "");
 
    if (_id !== undefined && _id !== "")
     this.appData.log(`${this.module}${func} input parameters _id: ${_id}`)
 
  }
  // Created a second parameter triggerChild to trigger the content substitution because the articleId doesn't change, but the content or aircraft might.
  @Input()
  set triggerChild(counter: number) {
    let func = "@Input/set triggerChild";
    
    this.triggerCount = (counter || 0);
 
    if (counter !== undefined )
     this.appData.log(`${this.module}${func} input parameters triggerChild: ${counter}`);

    if (this.markdown)
     this.contentSubs(); 
 
  }

  constructor(@Inject(DOCUMENT) document,
    public subs: SubstitutionService, 
    public appData: AppDataService, 
    public sanitizer: DomSanitizer, 
    private router: Router) { }


  ngOnInit() {
  }


  contentSubs() {
    let func = "contentSubs";
    let delay: number = 0;
    let displayImages: boolean = true;
    var convertedContent: string;

    if (!this.markdownConverter)
      this.markdownConverter = new showdown.Converter({tables: true}); // enable markdown tables https://github.com/showdownjs/showdown/wiki/Showdown-Options

    // TIMEOUT IS JUST FOR TESTING ON THE FIRST PAGE. ALL THE DATA MAY NOT BE LOADED YET WHEN THIS FIRES.
    if (this.appData.defaultAircraft.make === "") {
      delay = 500;
      this.appData.log(`${this.module}${func} - adding delay ${delay}`);
    }
      
    setTimeout(() => {
        this.subs.loopCounter = 0;
        
        convertedContent = this.subs.inlineCodeOut(this.markdown);
        convertedContent = this.subs.links(this.subs.aircraftValues(convertedContent)); // substitute aircraft values 
        convertedContent = this.subs.conditionalContent(convertedContent);

        if (displayImages) {

          this.subs.imageLookup(convertedContent).subscribe({
            next: data => {
              convertedContent = this.subs.inlineCodeIn(data);
              
              this.safeText = this.sanitizer.bypassSecurityTrustHtml(this.markdownConverter.makeHtml(convertedContent));
              //this.appData.log(this.safeText);
              //this.addButtonEvents(this.safeText);
            }
            , error: error => {
              this.safeText = this.sanitizer.bypassSecurityTrustHtml(this.markdownConverter.makeHtml(convertedContent));
              //this.addButtonEvents(this.safeText);
            }
          });
/*
          this.subs.imageLookup(convertedContent).subscribe((data) => { // image substitution - from cachedImages and lookup missing ones in database
            // after any images are loaded from database
            this.safeText = this.sanitizer.bypassSecurityTrustHtml(this.markdownConverter.makeHtml(data));
          }, error: error => {}
);
*/
        } else {
            this.safeText = this.sanitizer.bypassSecurityTrustHtml(this.markdownConverter.makeHtml(convertedContent));  
        }
      }, delay);
    
  }

  addButtonEvents(c: string) {

  return;
  
    // search thru the content for buttons.
    // get by element
    // add click event.
    var x = 0;
    var btn: string;
    var el: any;

  var e = document.getElementById("markdown1");
  console.log(e);
  //console.log(e.children);

  e = document.getElementById("target");
  //e.appendChild( )
  console.log(e);


    while (x < 100) {
      btn = `btn${x}`;
      el = document.getElementById(btn); // this returns null. Need to figure out why elements added to inner html can't be selected.

      // Security Concerns: code using innerHtml could be rejected. https://developer.mozilla.org/en-US/docs/Web/API/Element/innerHTML

      
      console.log(el);

      if (el) {
        el.addEventListener("click", function() {
          console.log(`btn${x} clicked`);
        })
        x++;
      }
      else 
        x = 1000;
    }
  }

  onDocumentClicked(e: any) {
    let func: string = "onDocumentClicked";

    if (["ion-button", "button"].includes( e.target.localName) && e.target.id.includes("btn") ) {
      //console.log(func);
      //console.log(e);
      console.log(`${func} id="${e.target.id}" outerText="${e.target.outerText}" `); // outerHTML: ${e.target.outerHTML}
      //alert(`${e.target.outerText} clicked`);
    }
  }

  /*
  //this.renderer.appendChild(btn, text);
      //this.renderer.appendChild(this.elmRef.nativeElement, btn);
      //this.renderer.addClass(this.elmRef.nativeElement, 'crazyClass');
      //this.renderer.setAttribute(myVal, "fill", "outline");

  */

  /* ORIGINAL KEEP
  contentSubs() {
    let func = "contentSubs";
    
    this.markdownConverter = new showdown.Converter({tables: true}); // enable markdown tables https://github.com/showdownjs/showdown/wiki/Showdown-Options
    //this.appData.log(`${this.module}${func} called. ${this.appData.article.title} ${this.appData.article._id} trigger: ${this.triggerCount}`);
    //this.appData.log(this.appData.article);
    let convertedContent = this.subs.aircraft(this.subs.links(this.appData.article.content)); // substitute values from memory

    this.subs.imageLookup(convertedContent).subscribe((data) => { // image substitution - from cachedImages and lookup missing ones in database
      // after any images are loaded from database
      this.safeText = this.sanitizer.bypassSecurityTrustHtml(this.markdownConverter.makeHtml(data));  
    });
  }
  */
}
