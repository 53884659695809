export class Coordinates {
    "latitude": number;
    "longitude":number; 
    "altitude"?: number;
    "speed"?: number;
    "altitudeAccuracy"?: number;
    "heading"?:  number;
    "accuracy"?: number
  
    constructor() {
      this.latitude = 0,
      this.longitude = 0,
      this.altitude = 0,
      this.speed = 0,
      this.altitudeAccuracy = 0,
      this.heading = 0,
      this.accuracy = 0
    }
  }