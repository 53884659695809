import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppDataService } from './services/app-data.service';
import { AuthenticationService } from './services/authentication.service';
import { CrudService } from './services/crud.service';
import { MenuDataService } from './services/menu-data.service';
import { SubstitutionService } from './services/substitution.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public module = "app.component.ts/";
  public displaySection: string = 'Learn'; // Sets which section is expanded at launch

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    private location: Location,
    public appData: AppDataService,
    public authentication: AuthenticationService,
    public crud: CrudService,
    public menuData: MenuDataService,
    public subs: SubstitutionService
  ) {
    var func: string = "constructor";
    var tkn = this.getUrlToken(window.location.href);
    this.location.replaceState(''); // wipes out anything on the URL bar and replaces with /#/
  
    if (tkn.length) {
      // validate token
      console.log(`token: ${tkn.length}`);
      this.appData.setToken(tkn);
      // validate token

      this.authentication.setAuthenticated(true);
      this.authentication.username ="";
    }

    console.log(`${this.module}constructor env: ${JSON.stringify(this.appData.environ)}`);
    
    // set the first page
    if (this.authentication.authenticated === false) {
      this.router.navigate(['login']);
    }
    else if (this.appData.environ.production === true)
      this.displaySection ="Learn";
    else { // change to whatever is useful in development;
      this.displaySection ="Learn";  // 'Learn'
      //this.router.navigate(['quiz']); // 'learn'
//      //this.router.navigate(['learn/6001f427a68e0104ce0f41f4'], { skipLocationChange: true }); // hides the URL parameters and changes
//      //this.router.navigate(['learn/5ff4e671873b892f0ef98059'], { skipLocationChange: true });
//      this.router.navigate(['assets']);
    }
    
      this.initializeApp();
  }

  async initializeApp() {
    let func = "initializeApp";
    this.appData.log(`${this.module}${func} authenticated: ${this.authentication.authenticated}`);
    
    await this.appData.initialize(this.appData.environ.platform);
    
    this.crud.setBaseUrl(this.appData.environ.apiUrl); 
    //this.crud.setToken(this.appData.environ.token); // this is already set in this.appData.setToken(tkn);

    if (this.authentication.authenticated) {
      this.appData.appInitialized = true;
      this.menuData.loadMenus(); // both admin and mobile app
      this.menuData.loadAdminMenu();      // only admin app
      this.appData.loadData();
    }

    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  getUrlToken(href: string) {
    var start: number;
    var token: string = "";
    start = href.indexOf("#/rnd=");
    if (start > 0) {
      token = href.slice(start+6);
    } 
      
    return token;
  }

   // this function hides and shows the sections of the menu.
   displayMenu(section: string) {
    this.appData.log(this.module + "displayMenu - "+ section + " clicked");
    if (this.displaySection === section)
      section = "";
    this.displaySection = section;
    if (section === "Logout") {
      this.authentication.logout();
      this.appData.logout();
      this.router.navigate(['login']);
    }
  }

  isError(page: any) {
    if (page.error === true)
      return "danger";
    return "";
  }

  navToPage(page: any) {
    let func = "navToPage ";
    this.appData.log(`${this.module}${func} clicked: ${page.title}  ${(!page.hasOwnProperty('url') ? " no URL " : page.url)}`);
    
    var sections = ["Learn", "Fly", "Emergency", "Tutorial"];

    if (page.hasOwnProperty('url') && page.url.length) {
      this.router.navigate([page.url], { skipLocationChange: true });
    } else if (sections.includes(page.section) ) { 
      let url = "learn/_id=" + page._id;
      this.appData.log(this.module + func + url);
      this.router.navigate(["learn", page._id], { skipLocationChange: true });
    }
  }

  new(section: string) {
    let func = "new";
    this.appData.log(`${this.module}${func} NOOP`);
    /*
      let page: PageMenu;
      page = {
        _id: "0",
        index: -1,
        title: "new " + section + " page",
        name: "new page",
      //  component: HomePage,
        section: section
      }
    */  
  }

  

}
