import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

// Dummy service to get the admin app to compile. The actual service is required for the mobile app version, but there is some cross utilization

@Injectable({
  providedIn: 'root'
})
export class DatabaseService {

  constructor() { }

  runQuery(sqlcmd: string, parameters: any): Observable<any>{
    return new Observable(observer => {
      observer.next([]);
      observer.complete();
    })
  }

  runQueryPromise(sqlcmd: string, parameters: any): Promise<any> {
    let func: string = "runQueryPromise";

    return Promise.resolve();
    }
}
