import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  //{
  //  path: 'home',
  //  loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  //},
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'airports',
    loadChildren: () => import('./pages/airports/airports.module').then( m => m.AirportsPageModule)
  },
  {
    path: 'assets',
    loadChildren: () => import('./pages/assets/assets.module').then( m => m.AssetsPageModule)
  },
  {
    path: 'choose-aircraft',
    loadChildren: () => import('./pages/choose-aircraft/choose-aircraft.module').then( m => m.ChooseAircraftPageModule)
  },
  {
    path: 'content-help',
    loadChildren: () => import('./pages/content-help/content-help.module').then( m => m.ContentHelpPageModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'learn',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'learn/:_id',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'lookups',
    loadChildren: () => import('./pages/lookups/lookups.module').then( m => m.LookupsPageModule)
  },
  {
    path: 'makes',
    loadChildren: () => import('./pages/makes/makes.module').then( m => m.MakesPageModule)
  },
  {
    path: 'publish',
    loadChildren: () => import('./pages/publish/publish.module').then( m => m.PublishPageModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./pages/users/users.module').then( m => m.UsersPageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'choose-aircraft',
    loadChildren: () => import('./pages/choose-aircraft/choose-aircraft.module').then( m => m.ChooseAircraftPageModule)
  },
  {
    path: 'makemodelmodal',
    loadChildren: () => import('./pages/makesmodelsmodal/makesmodelsmodal.module').then( m => m.MakesModelsModalPageModule)
  },
  {
    path: 'quiz',
    loadChildren: () => import('./pages/quiz/quiz.module').then( m => m.QuizPageModule)
  },
  // Default page
  { path: '**',  loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule)},

  
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules /*, relativeLinkResolution: 'legacy' */})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
