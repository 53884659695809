import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



// include all the custom components here, then add to declarations and exports below.
import { MarkdownComponent } from './markdown/markdown.component';
//import { ModelComponent } from './model/model.component';

//https://forum.ionicframework.com/t/how-to-correctly-implement-a-components-module-ts-in-ionic/87702/5
// search for the post by abusharaf near the bottom

@NgModule({
  declarations: [MarkdownComponent, 
    // ModelComponent
  ],
  imports: [FormsModule, ReactiveFormsModule],
  exports: [MarkdownComponent, 
    //ModelComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ComponentsModule { }

/*
NOTE: Include this code in each {page}.module.ts that uses these components. Nothing needs to be added to the {page}.page.ts code.

import { ComponentsModule } from '../../components/components.module';
...
imports: [
  ...
  ComponentsModule
]
*/