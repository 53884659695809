import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';
import * as _ from 'lodash';

import { HTTP } from '@ionic-native/http/ngx';

@Injectable({
  providedIn: 'root'
})
export class CrudService {
  public module = "crud.service.ts/";
  baseUrl: string = '';
  token  : string = '';

  constructor(public http: HttpClient, public http2: HTTP) { 
    console.log(`${this.module}constructor fired.`);
  }

  setBaseUrl(url: string) {
    this.baseUrl = url;
  }
  setToken(token:string) {
    this.token = token;
  }

  getAll(apiName: string): Observable<any> {
    let func: string = "getAll"
    //console.log(`${func} appData.baseUrl ${this.baseUrl}${apiName}`);
    
    var headers = new HttpHeaders(
      {
        'Content-Type':  'application/json',
        'Accept':  'application/json',
        'token': this.token
      }
    );
   
    return this.http.get(this.baseUrl+apiName, { headers: headers}).pipe(map(data => _.values(data))).pipe(catchError(this.handleError));
    //return this.http.get(finalURL, { headers: headers }).pipe(map((res: Response) => res.json())).pipe(catchError(this.handleError));
  }

  getOne(apiname: string, id: string): Observable<any> {
    var headers = new HttpHeaders(
      {
        'Content-Type':  'application/json',
        'Accept':  'application/json',
        'token': this.token
      }
    );

    return this.http.get(this.baseUrl+apiname + "/" + id, { headers: headers }).pipe(catchError(this.handleError));
  }
  
  async getAllPromise(apiName: string): Promise<any>{
    let func: string = "getAllPromise";

    return new Promise( resolve => {    
      var headers = {
        'Content-Type':  'application/json',
        'Accept':  'application/json',
        'token': this.token
      }    
  
       this.http2.get(this.baseUrl + apiName, {}, headers)
       .then((data) => {
         //console.log(`${func} ${JSON.parse(data.data).length} records`);
         //console.log(JSON.parse(data.data));
        resolve(JSON.parse(data.data));
       })
       .catch((error) => {
         console.error(`${this.module}${func} ${JSON.stringify(error)}`);
        resolve(JSON.stringify(error));;
       })
        
    });
      /*
      this.http2.get(this.baseUrl + apiName, [], {headers: headers} )
      .then((data) => {
        console.log(`${func} records: ${JSON.parse(data.data).length}`)
        resolve(JSON.parse(data.data));
      }).catch((error) => {
        console.error(`${func} Error: ${JSON.stringify(error)}`);
        Promise.reject(error);
      });
      */
  }

  create(apiname: string, data: any): Observable<any> {
    var headers = new HttpHeaders(
      {
        'Content-Type':  'application/json',
        'Accept':  'application/json',
        'token': this.token
      }
    );
    //return this._http.get<T[]>(this._base).pipe(catchError(this.handleError));
   return this.http.post(this.baseUrl+apiname, data, { headers: headers }).pipe(catchError(this.handleError));
  }

  update( apiname: string, data: any): Observable<any> {
    var headers = new HttpHeaders(
      {
        'Content-Type':  'application/json',
        'Accept':  'application/json',
        'token': this.token
      }
    );
    //return this._http.put<T>(this._base + "/" + id, t, {});
    return this.http.put(this.baseUrl+apiname, data, { headers: headers }).pipe(catchError(this.handleError));
  }

  delete(apiname: string, id: string): Observable<any> {
    var headers = new HttpHeaders(
      {
        'Content-Type':  'application/json',
        'Accept':  'application/json',
        'token': this.token
      }
    );

    return this.http.delete(this.baseUrl+apiname + '/' + id, { headers: headers }).pipe(catchError(this.handleError));
  }
  
  handleError(error: Response | any) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    
    return throwError(errorMessage);
  }
}
