import { Injectable } from '@angular/core';

import { AppDataService } from './app-data.service';
import { CrudService } from './crud.service';
import { DatabaseService } from './database.service';

import { Menu } from '../classes/menu';

export class MenuGroup {
  section: string;
  addMore: boolean;
  menu: Menu[];
}
@Injectable({
  providedIn: 'root'
})
export class MenuDataService {
  module = "menu-data.service.ts/";

  menus: MenuGroup[] = [];
  public firstMenu: any = [];
  public menuDisplay: string = "Learn";
  public SectionEnum = {"Learn": 0, "Fly": 1, "Emergency": 2, "Tutorial": 3, "Settings": 4};
  public hideMenu: boolean = false; // mobile
  
  
  constructor(public appData: AppDataService, public crud: CrudService, public databaseService: DatabaseService) {
    this.appData.log(`${this.module}constructor fired`);

    this.menus[0] = { // initialize. Addresses a timing issue when the display is ready before data is loaded.
      section: "",
      addMore: true,
      menu: []
    }
   }

   async loadMenus(sqlite?: boolean) {
    await this.loadMenu("Learn", 0, sqlite);
    await this.loadMenu("Fly", 1, sqlite);
    await this.loadMenu("Emergency", 2, sqlite);
    await this.loadMenu("Tutorial", 3, sqlite);
  }

  // Common
  async loadMenu(section: string, index: number, sqlite?: boolean) {
    let func = "loadMenu ";
    this.appData.log(`${this.module}${func} fired: ${section}(${index}) sqlite: ${sqlite}`);

    this.menus[index] = {
      section: section,
      addMore: true,
      menu:  [] as Menu[]
    }

    if (sqlite) {
      let sqlcmd = 
`SELECT _id, section, title, error, sortorder, createdate, updatedate, publishdate \
FROM articles WHERE section = ? \
AND status = 'A'
ORDER BY sortorder`;

       await this.databaseService.runQueryPromise(sqlcmd, [section]).then((results) => {
          //console.log(results);
          this.populateMenuArray(section, index, results);
        });

        //var results = await this.databaseService.runQuery(sqlcmd, []);
        //console.log(results);
        //this.populateMenuArray(section, index, results);
  
      /*
      this.databaseService.getAll(sqlcmd).subscribe( {
        next: results => {
          var values = results.values;
          //console.log(values);
          this.populateMenuArray(section, index, values);
        },
        error: error => {
          let errorMessage = error.errorMesage;
          this.appData.log(this.module + func + "error");
          this.appData.log(error);
        }
      })
      */
      } else {
      this.crud.getAll("articles?section="+section).subscribe(  {

        next: results => {
          this.appData.log(`${this.module}${func} subscribe|next  ${section}: ${results.length} records.`); 
          //console.log(results);
          this.populateMenuArray(section, index, results);    
        },
        error: error => {
          let errorMessage = error.errorMesage;
          this.appData.log(this.module + func + "error");
          this.appData.log(error);
        }
      });
    }
  }

  populateMenuArray(section: string, index: number, results: any) {
    for (var i = 0; i < Object.keys(results).length; i++) {
      this.menus[index].menu[i] = {
        title: results[i].title,
        _id  : results[i]._id,
        index: i,
        tabIndex: index, // mobileApp            
        icon: "",
        section: section, //"",
        error: results[i].error,
        createdate: results[i].createdate,
        updatedate: results[i].updatedate,
        publishdate: results[i].publishdate
      }
    }
    if (index === 0 && this.firstMenu.length === 0) {
      this.firstMenu = this.menus[0].menu[0]; // this is used in the event triggered after the login page runs.
    }
  }

  loadMobileSetupMenu() {
    let func: string = 'loadMobileSetupMenu';
    let section: string = "Settings";
    let setupMenuIndex: number = this.SectionEnum[section];  // timing //this.menuData.menus.length; // next open array index
    let itemIndex: number = 0;
    this.menus[setupMenuIndex] = {
      section: section,
      addMore: false,
      menu: []
    }
    
    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Manage Profile",
      _id  : "",
      index: itemIndex,
      url: "profile",
      icon: "",
      section: ""
    }
    itemIndex++;

    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Show Tutorial",
      _id  : "",
      index: itemIndex,
      url: "tutorial",
      icon: "",
      section: ""
    }
    itemIndex++;

    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Admin/Testing",
      _id  : "",
      index: itemIndex,
      url: "admin",
      icon: "",
      section: ""
    }
    itemIndex++;

    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Aircraft Variables",
      _id  : "",
      index: itemIndex,
      url: "varsubs",
      icon: "",
      section: ""
    }
    itemIndex++;

  }

  loadAdminMenu() { // this is in addition to the common data loaded in menu-data.ts
    // Setup - Should setup be in an include file and read it in? 
    
    let setupMenuIndex: number = 4; // timing //this.menuData.menus.length; // next open array index
    let itemIndex: number = 0;
    this.menus[setupMenuIndex] = {
      section: "Settings",
      addMore: false,
      menu: []
    }

    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "My Hangar",
      _id  : "",
      index: itemIndex,
      url: "choose-aircraft",
      icon: "",
      section: ""
    }
    itemIndex++;
    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Makes & Models",
      _id  : "",
      index: itemIndex,
      url: "makes",
      icon: "",
      section: ""
    }
    itemIndex++;
    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Lookups",
      _id  : "",
      index: itemIndex,
      url: "lookups",
      icon: "",
      section: ""
    }
    itemIndex++;
    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Images",
      _id  : "",
      index: itemIndex,
      url: "assets",
      icon: "",
      section: ""
    }
    itemIndex++;
    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Airports",
      _id  : "",
      index: itemIndex,
      url: "airports",
      icon: "",
      section: ""
    }
    itemIndex++;
    /*
    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Triggers",
      _id  : "",
      index: itemIndex,
      url: "triggers",
      icon: "",
      section: ""
    }
    itemIndex++;
    */
    
    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Users",
      _id  : "",
      index: itemIndex,
      url: "users",
      icon: "",
      section: ""
    }
    itemIndex++;

    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Quizzes",
      _id  : "",
      index: itemIndex,
      url: "quiz",
      icon: "",
      section: ""
    }
    itemIndex++;
    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Publish",
      _id  : "",
      index: itemIndex,
      url: "publish",
      icon: "",
      section: ""
    }
    itemIndex++;
    
    this.menus[setupMenuIndex].menu[itemIndex] = {
      title: "Content Help",
      _id  : "",
      index: itemIndex,
      url: "content-help",
      icon: "",
      section: ""
    }
    itemIndex++;
    
    let logoutMenuIndex: number = setupMenuIndex+1;
    this.menus[logoutMenuIndex] = {
      section: "Logout",
      addMore: false,
      menu: []
    }
  }

}