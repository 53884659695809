import { Injectable } from '@angular/core';
import { Observable, forkJoin, throwError } from 'rxjs';

import { AppDataService  } from './app-data.service';
import { MenuDataService } from './menu-data.service';
import { CrudService }     from './crud.service';
import { DatabaseService } from './database.service';

import { Asset }  from '../classes/asset';
import { MyAircraft } from '../classes/myaircraft';

class PageImageClass {
  cache: boolean;
  title: string;
  fullname: string;
  imageName: string;
  caption: string;
  credit : string;
  showCaption: boolean;
  showCredit:  boolean;
  showThumbnail: boolean;
  showPlain: boolean;
  imgReference: string;
  image: string;
  width: string;
}

@Injectable({
  providedIn: 'root'
})
export class SubstitutionService {
  
  module="substitution.ts/";
  private maxLoops: number = 20;
  public  loopCounter: number = 0;
  private imageDebug = false;

  constructor(public appData: AppDataService, public crud: CrudService, public menuData: MenuDataService, public databaseService: DatabaseService) {
    this.appData.log(`${this.module}constructor fired`);
  }  

  links(content: any) {
    var func: string = 'links';
    var start: number, end: number, offset: number = 0, btnLabel: string, pageLink: string
    var fulltext: string, btn: string;
    var buttonCount: number = 0;
    var btnId: string;
    var clickAction: string;
    var designatedAction: string; // when "action=some page" is included in the button definition
    var recognizedActions = ['next', 'next page', 'ok', 'continue', 'yes']; // this list should be lower case

    while ( ( start = content.indexOf("%%button=", offset)) > 0) {
      end = content.indexOf("%%", start+1);
      fulltext = content.slice(start, end+2);

      if (start > 5 && content.slice(start-6, start) === "<code>") {
        offset = start + 1;
        continue;
      }
      
      let options = fulltext.split(',')
      for (var j = 0; j < options.length; j++) {
        options[j] = options[j].replace(/%%/g, ''); // get rid of the %%
      }
      
      btnLabel = options[0].slice(7, options[0].length); // strip off 'button=' leaving the name of the button
      pageLink = btnLabel; // they start the same. pageLink can be overridden by options[i] containing 'action=something'
      //let css = "button-md";
      let buttonStyleCount = 0;
      let css_color = "primary"; // Standard colors 
      let css_border = "clear";  // clear, outline solid
      let css_size   = "small";  // small, large, default, normal
      let css_shape = ""; // only option is round
      designatedAction = "";

      for (var i = 1; i < options.length; i++) {// intentionally skipping the first one which is the btnLabel and link unless overridden with action=
        options[i] = options[i].replace(" ", "").toLowerCase();
        switch(options[i]) {
          case "clear":
            css_border = "clear";
            buttonStyleCount ++;
            //css += " button-" + options[i] + "-md"
            break;
          case "outline":
            css_border = "outline";
            buttonStyleCount++
            //css += " button-" + options[i] + "-md"
            break;
          case "solid":
            css_border="solid";
            buttonStyleCount++;
            //css_border = "solid"; // this isn't real but it works.
            //css += " button-md"
            break;
          
          case "round":
            //css += " button-" + options[i] + "-md"
            css_shape='shape="round"';
            break;
          
          case "large":
            //css_size = " button-" + options[i] + "-md"
            css_size="large";
            break;
          case "normal": 
          case "default":
            css_size = "default"; // this is the ionic default and we already have button-md
            break;
          case "small":
            //css_size = " button-" + options[i] + "-md"
            css_size="small";
            break;
          
          case "primary":
          case "secondary":
          case "tertiary":
          case "success":
          case "warning":
          case "danger":
          case "light":
          case "medium":
          case "dark":
            css_color = options[i];
            break;
          default:
            // put action=<page or link name> in default case because we can't to a case on a substring.
            if (options[i].substring(0, 7) === 'action=') {
              pageLink = options[i].substring(7);
              console.log(`${func} found keyword 'action': '${options[i]}' '${pageLink}'`);

              if (recognizedActions.includes(pageLink.toLowerCase())) {
                //console.log(`${func} pageLink='${pageLink}' NEXT PAGE action recognized.`)
                //console.log(this.appData.article);
                let menuId = this.menuData.SectionEnum[this.appData.article.section]; // get this article's section.
                
                try { // try/catch because this blows up in the documentation (and makes the page fail) when there isn't a 'next' page
                  let articleIndex = this.menuData.menus[menuId].menu.findIndex(m => m._id === this.appData.article._id); // get this article's index
                  //console.log(`${func} section: ${menuId} articleIndex: ${articleIndex}`);

                  articleIndex++;
                  page = this.menuData.menus[menuId].menu[articleIndex];
                  //console.log(`${func} next page: '${page.title}' id: ${page._id}`);

                  designatedAction=`routerLink="home/${page._id}"`;
                } catch {}

              } else {
                // find the specific page by name in the navigation.
                // this falls through to the menuData.menu.find below and looks the redefined pageLink up there.
              }              
            }
            else // real default case.
              console.log(`${func} unknown option '${options[i]}'`);
            break;
        }
      } // end option parsing

      // is the link valid?
      var section: number;
      var page: any;
      var coloroverride: string = "";//"danger"; // override the color if the link isn't valid.
      //var clickAction: string = `click('${btnLabel}')`;
      var pageid: string = "";

// routerLink="home${pageid}" routerDirection="root"
      if (designatedAction.length > 0)
        clickAction = designatedAction;
      else
        clickAction = `clickAction=${btnLabel}`;

      // need to figure out how to modify DOM instead of just producing HTML that looks like it could function.
      for (section = 0; section < this.menuData.menus.length; section++) {
        if (page = this.menuData.menus[section].menu.find(menu => menu.title.toUpperCase() === pageLink.toUpperCase())) {
          //console.log(`${func} found '${pageLink}'`)
          pageid = "/"+page._id;
          clickAction=`routerLink="home${pageid}"`; // routerDirection="root"
          coloroverride = "";
          break;
        }
      }
      // if (this app is admin)
      if (coloroverride.length) {
        css_color = coloroverride;
        //btnLabel = btnLabel + " (Page Not Found)";
      }

      //console.warn(`${func} clickAction = '${clickAction}'  (${btnLabel})`);

      btnId = `btn${buttonCount}`;
      //btn=`<ion-button size="${css_size}" fill="${css_border}" color="${css_color}" ${css_shape} (click)="${clickAction}">${btnLabel}</ion-button>` //button-md button-small-md button-clear-md button-clear-md-secondary
      btn=`<ion-button id="${btnId}" size="${css_size}" fill="${css_border}" color="${css_color}" ${css_shape} ${clickAction}>${btnLabel}</ion-button>`
      //console.log(btn);
      // rebuilding the string since global replace will replace buttons in <code> blocks.
      content = content.slice(0, start) + btn + content.slice(end+2, content.length);
      //content = content.replace(fulltext, btn, )
      offset = end;
      buttonCount++;
    }
    return content;
  }

  // substitute aircraft variables.
  aircraftValues(content: any) {
    let func: string ="aircraftValues";
    //this.appData.log(`${this.module}${func} called `);
    var myAirModel = new MyAircraft(); // use the class so all properties are accounted for. 
    var modkey: string;

    // username (not an aircraft value)
    key = 'username';
    modkey = "%%" + key + "%%";
    content = content.replace(new RegExp(modkey, "g"), this.appData.username);

    // replace all aircraft variables like %%landinggear%%
    for (var key in myAirModel) {

      if (this.appData.defaultAircraft.hasOwnProperty(key)) {
        modkey = "%%" + key + "%%";
        //this.log(key + " -> " + this.appData.defaultAircraft[key] + " modkey: " + modkey);
        
        // get the lookup.description display value (if it has one)
        let lkp = this.appData.lookups.find( lookup =>  lookup.type.toUpperCase() === key.toUpperCase() && lookup.value === this.appData.defaultAircraft[key])

        if (lkp !== undefined)
          content = content.replace(new RegExp(modkey, "g"), lkp.description);
        else
          content = content.replace(new RegExp(modkey, "g"), this.appData.defaultAircraft[key]);
      } else { // defaultAircraft does not have this key.
        modkey = "%%" + key + "%%";
        console.warn (`${this.module}${func} '${this.appData.defaultAircraft.make} ${this.appData.defaultAircraft.model}'.${key} property not found. Replacing ${modkey} with blank`);
        if (this.appData.debug === true)
          content = content.replace(new RegExp(modkey, "g"), "<div class='error'>Missing Property</div>"); // display error 
        else
          content = content.replace(new RegExp(modkey, "g"), ""); // hide 
      }
    }
    return content;
  }

  conditionalContent(content: any) {
    let func: string = "recursion";
    var exprStart: RegExp, exprEnd: RegExp;
    var resultStart: any, resultEnd: any;
    var essentialpart: string;
    var key: string;
    var equality: string;
    var value: string;
    var aircraftValue: string;

    var before: string;
    var after : string;
    var payload  : string;
    var bStyle: string; 
    var eStyle: string;
    var endOffset: number = 0; // exprEnd RegExp search doesnt include the closing %%
    
    // don't allow runaway loops when testing.
    if (this.loopCounter >= this.maxLoops)
      return "";
    this.loopCounter ++;
  
    // match pattern %%if variable=value%% with or without spaces before the if, around the =, and before the ending %%
    exprStart = new RegExp("%%\\s*if\\s*[a-z0-9]+\\s*[!=in]+\\s*[\(]?[a-z\\s0-9,]+[\)]?\\s*%%", "i"); // search for any matching IF pattern
    // OLD exprEnd   = new RegExp("%%\\s*[endif]+\\s*[%%]*", "i");  
    // search for the next 'end' or 'if' pattern, but not 'img'. since 'if' is also part of 'endif', this regex finds either an 'if' or an 'endif'
    // the %'s are optional on the end since they won't be immediately after the 'if', but we want them to complete the string on an 'endif'
    // this says: [2 %'s][0 or more spaces][2 or more chars in the list 'endif'][0 or more spaces][optional %'s]
    exprEnd   = new RegExp("[%]{2}\\s*[endif]{2,}\\s*[%]*", "i");

    resultStart = exprStart.exec(content);
    if (resultStart)
      resultEnd = exprEnd.exec(content.slice(resultStart.index + resultStart[0].length));  // start after resultStart tag.

    // need to figure out how to handle content in ``` markdown code mode.

    // should this be while (resultStart = exprStart.exec(content)) 
    while (resultStart && resultEnd && this.loopCounter < this.maxLoops) { // found a %%if 
      this.log(resultStart);
      this.log(`[${this.loopCounter}] Start: ${resultStart}  ## pos:${resultStart.index} length:${resultStart[0].length}`);

      if (resultEnd[0].includes('endif')) {
        essentialpart = resultStart[0].replace(/%%/g, '').trim().replace(/^if/, '').trim(); // strip off %%, if and trailing spaces.
        equality = "=";
        if (essentialpart.includes("!"))
          equality = "!=";
        else if (essentialpart.includes(" in ("))   // %%if flightcontrols in (yoke, center stick)%%
          equality = "in";

        key = essentialpart.slice(0, essentialpart.indexOf(equality)).trim();
        
        if (equality === "=" || equality === "!=")
          value = essentialpart.slice(essentialpart.indexOf(equality)+equality.length).trim();
        else if (equality === "in") {
          value = essentialpart.slice(essentialpart.indexOf("(")+1).trim();
          value = value.replace(")", "");
        }

        this.log(resultEnd);
        //offsetCalc = resultEnd.input.substr(resultEnd.index+2, 15); // add 2 to get past the initial %%
        //endOffset  = offsetCalc.indexOf("%%") +2 - resultEnd[0].length + 2; // add 2 to account for the skipped %% in the initial string, add 2 more for the %% we want to skip
        //this.log(`offset: '${offsetCalc}' length: ${resultEnd[0].length} offset: ${endOffset}`);
        
        this.log(`[${this.loopCounter}] End: ${resultEnd}  ## pos:${resultEnd.index} length:${resultEnd[0].length}`);
        this.log(`[${this.loopCounter}] ${essentialpart} key:'${key}' value:'${value}' equality: '${equality}' EndOffset: ${endOffset}`);
        
        // some defaultAircraft are arrays. some are plain values
        if (Array.isArray(this.appData.defaultAircraft[key]) ) {
          this.log(`defaultAircraft[${key}][0]: ${this.appData.defaultAircraft[key][0]}`);
          aircraftValue = this.appData.defaultAircraft[key][0];
        } else { // value
          this.log(`defaultAircraft[${key}]: ${this.appData.defaultAircraft[key]}`);
          aircraftValue = this.appData.defaultAircraft[key];
        }

        before  = content.substring(0, resultStart.index);
        payload = content.substring(resultStart.index + resultStart[0].length, resultStart.index + resultStart[0].length + resultEnd.index);
        after   = content.substring(resultStart.index + resultStart[0].length + resultEnd.index + resultEnd[0].length + endOffset);

         //this.log(`before: ${before}`);
         //this.log(`payload ${payload}`);
         //this.log(`after: ${after}`);
        if ((equality=== "=" || equality === "in") && aircraftValue === value) {
          if (this.appData.debug === true) { // make content visible for testing
            bStyle = "<div class='happy'>";
            eStyle = "</div>";
          } else {
            bStyle="";
            eStyle="";
          }
        }
        else if (equality === "!=" && aircraftValue !== value) {
          if (this.appData.debug === true) { // make content visible for testing
            bStyle = "<div class='happy'>";
            eStyle = "</div>";
          } else {
            bStyle="";
            eStyle="";
          }
        } else if (equality === "in" && value.includes(aircraftValue)) {
          if (this.appData.debug === true) { // make content visible for testing
            bStyle = "<div class='happy'>";
            eStyle = "</div>";
          } else {
            bStyle="";
            eStyle="";
          }
        } else {
          //console.warn(`No match (hide):  ${aircraftValue} => '${key}' equality: '${equality}' value: '${value}'`);
          if (this.appData.debug === true) { // making content visible for testing
            bStyle="<div class='sad'>";
            eStyle="</div>";
          } else {
            bStyle="<div hidden>"; // hidden
            eStyle="</div>";
          }
        }

        content = `${before}${bStyle}${payload}${eStyle}${after}`;       

      } else {
        // the next substitution token is a %%if not %%endif
        this.log(`Next token is not endif ${resultEnd} - initiating recursion`);

        before  = content.substring(0, resultStart.index + resultStart[0].length + resultEnd.index);  // save everything from the beginning to the end of the payload
        payload = content.substring(resultStart.index + resultStart[0].length); // send the rest in

        //console.error(`before recursion ${before}`);

        after = this.conditionalContent(payload);
        content = before + after;
      }

      // reset for the next loop
      resultStart = exprStart.exec(content);
      if (resultStart)
        resultEnd = exprEnd.exec(content.slice(resultStart.index + resultStart[0].length));  // start after resultStart tag.
      else
        resultEnd = null;

      this.loopCounter ++;
    }
    return content;

  }

  // separate function so susbstitution debugging can be separate from regular logging
  log(data: any) {
    if (this.appData.debug) 
      console.log(data);
  }


  // Lookup images in the cache then the database. Then cache db images so it is faster & free the next time the image is needed.
  imageLookup(content: string) : Observable<any>{
    return new Observable(observer => {

      let func: string = "imageLookup";
      this.imageLog(`${this.module}${func} ${this.appData.imageCache.length} images cached.`);
  
      let pageImages = [] as PageImageClass[]; // array of images to be looked up in the database.
      var offset: number = 0;
      var start: number, end: number, fullname: string 
      var cTitle: string; // Title from |c|ontent
      var myImg: string;  // html img string including base64 image string from assets.image
      var callbacks = []; // array of callbacks
      var cbindex: number = 0; // callback index
      let c = content;
      let cachedImage = {} as Asset;
      var exprStart: RegExp;
      var resultStart: any;
      var showCaption: boolean, showCredit: boolean, showThumbnail: boolean, showPlain: boolean, width: string;

      //exprStart = new RegExp("%%\\s*img\\s*[=]\\s*[a-z\\s._0-9]+\\s*%%", "i"); // search for any matching IF pattern
      exprStart = new RegExp("%%\\s*img\\s*[=]\\s*[a-z\\s,!@=#$%^&()-._0-9]+?\\s*?%%", "i"); // search for any matching IF pattern  ? makes Regex greedy and stop at the first match
      resultStart = exprStart.exec(content);  

      while ( resultStart ) {
        this.imageLog(resultStart);
        fullname = resultStart;
        cTitle = resultStart[0].replace(/%%/g, '').trim().replace(/^img/, '').trim().replace(/=/, '').trim(); // strip off %%, img, = and trailing spaces.
        end = c.indexOf("%%", start+1);

        this.imageLog(`${this.module}${func} image reference: fullname: ${fullname} cTitle: '${cTitle}'`);
        
        showCaption = true;
        showCredit = true;
        showThumbnail = false;
        showPlain = false;
        width="100%";

        let options = cTitle.split(",");
        if (options.length > 1) {
          cTitle = options[0].trim();
          for (var i = 1; i < options.length; i++) {
            options[i] = options[i].trim();
            switch(options[i]) {
              case "-caption":
              case "-captions":
                showCaption = false;
                break;
              case "-credit":
              case "-credits":
                showCredit = false;
                break;
              case "thumbnail":
                showThumbnail = true;
                break;
              case "plain":
                showPlain = true;
                break;
              default:
                if (options[i].includes("width=")) {
                  width = options[i].substring(6);
                } else {
                  this.appData.log(`${this.module}${func} ${cTitle} - unknown option '${options[i]}' `);
                }
                break;
            }
          }
          this.imageLog(`WE HAVE OPTIONS ${cTitle} showCaption: ${showCaption} showCredit: ${showCredit}`);
        }
                
        cachedImage = this.appData.imageCache.find( ({ title }) => title === cTitle); // lookup in imageCache
        
        if (cachedImage !== undefined && cachedImage.hasOwnProperty("image")) {
          this.imageLog(`${this.module}${func} - found cachedImage '${cachedImage.title}'`);
          //myImg = `<img src="data:image/png;base64, ${cachedImage.image} "/>`
          myImg = `@@img${cbindex}@@`; // add a placeholder 

          content = content.replace(fullname, myImg); // replace the image.
          pageImages[cbindex] = {
            cache: true,
            title: cTitle,
            fullname: "", //fullname, This is the regex result object.
            imageName: resultStart[0],
            caption: cachedImage.description,
            credit : cachedImage.credit,
            showCaption: showCaption,
            showCredit:  showCredit,
            showThumbnail: showThumbnail,
            showPlain: showPlain,
            image: cachedImage.image,
            imgReference: myImg, //`<img src="data:image/png;base64, ##img${cbindex}## "/>`
            width: width
          };

        } else {  // image not found in cache. Lookup in db.
          myImg = `@@img${cbindex}@@`; // add a placeholder 
          this.imageLog(`${this.module}${func} image not cached: '${fullname}', placeholder '${myImg}'. Loading...`);  
          
          pageImages[cbindex] = {
            cache: false,
            title: cTitle,
            fullname: "", //fullname, This is the regex result object.
            imageName: resultStart[0],
            caption: "",
            credit : "",
            showCaption: showCaption,
            showCredit:  showCredit,
            showThumbnail: showThumbnail,
            showPlain: showPlain,
            image: "",
            imgReference: myImg, //`<img src="data:image/png;base64, ##img${cbindex}## "/>`
            width: width
          };

          content = content.replace(fullname, myImg); // replace the image name so while loop can end.

       
          if (this.appData.environ.dataSource === "sqlite") {
            let sqlcmd: string = `SELECT * FROM assets WHERE title = '${cTitle}' AND status = 'A'`;
            callbacks[callbacks.length] = this.databaseService.runQuery(sqlcmd, []); 
          } else {
            callbacks[callbacks.length] = this.crud.getAll("assets/title/" + cTitle); // add this image lookup to list of callbacks.
          }
        }
        offset = end; // setup for the next loop iteration
        cbindex ++;

        resultStart = exprStart.exec(content);

      }

      if (callbacks && callbacks.length) { // if there are any callbacks
        this.imageLog(`${this.module}${func} ${callbacks.length} callbacks`);
        
        // this waits for all the observables to return. each observable is an array. each result is an array (even though there will only be one record in each). 
        // so the final resultset is an array of arrays.
        forkJoin(callbacks).subscribe(results => { 
          this.imageLog(`${this.module}${func} forkJoin callback called. ${results.length}`);
        
          // foreach callback, replace the @@imgXX@@ with the actual image.
          for (var i=0; i< results.length; i++) {
            // find match in pageImages
            if (results[i][0] !== undefined) {
              for (var p = 0; p < pageImages.length; p++) {
                if (! pageImages[p].cache && pageImages[p].title === results[i][0].title) {
                  if (results[i][0] === undefined || !results[i][0].hasOwnProperty("image")) {
                    pageImages[p].image = "";
                  }
                  else {
                    this.imageLog(Object.keys(results[i][0]));
                    this.imageLog(typeof(results[i][0].image));
                    this.imageLog(JSON.stringify(results[i][0].image.substring(0,50)));
                    if (results[i][0].image.substring(0,10) !== "data:image") {
                      //console.log(`a3 ${JSON.stringify(Object.keys(results[i][0]))}`);
                      results[i][0].image = "data:image/jpeg;base64," + results[i][0].image;
                    }
                    pageImages[p].image = results[i][0].image; 
                  }
                  pageImages[p].caption = results[i][0].description || "";
                  pageImages[p].credit  = results[i][0].credit      || "";
                  pageImages[p].cache = true;

                  // add to the cache.
                  var z = this.appData.imageCache.length;
                  this.appData.imageCache[z] = results[i][0] as Asset; // cache the image so it only gets looked up once.
                  if (results[i][0] === undefined || !results[i][0].hasOwnProperty("image")) {
                    this.appData.imageCache[z].image = "";
                  }

                  break;
                }
              }
            }
          }

          content = this.imageOutput(content, pageImages);
          observer.next(content);
          observer.complete();
        });
      } else { // no callbacks. just return
        this.imageLog(`${this.module}${func} No callbacks. forkJoin NOT called.`);
        content = this.imageOutput(content, pageImages);
        
        observer.next(content);
        observer.complete();
      }
    }); 
  }

  imageLog(msg: any) {
    if (this.imageDebug === true)
      console.warn(msg);
  }
  

  // all image loading and caching is complete
  // walk thru the content and substitute the final result or error
  imageOutput(content: string, pageImages: PageImageClass[]) {
    let func: string = "imageOutput";
    this.imageLog(`${this.module}${func} here.`);
    //this.imageLog(pageImages);
    var strImage: string = "";
    var strWidth: string = "";
    //var cachedImage: Asset;
    var cardPattern: string =
`<ion-card>
  !!img!!
  <ion-card-header>
    !!caption!!
    !!credit!!
  </ion-card-header>
</ion-card>`;

    for (var i = 0; i < pageImages.length; i++) {
      strImage = "";
      // if this image was not found in the database up to this point, add a placeholder to the imageCache.
      if (!pageImages[i].cache) {
        var item: number = this.appData.imageCache.length;
        this.appData.imageCache[item] = {
          _id:          "0",
          filename:     "",
          filepath:     "",
          title:        pageImages[i].title,
          description:  "",
          keywords:     "",
          credit:       "",
          image:        "",
          thumbnail:    "",
          status:       "",
          sortorder:    0,
          createdate:   null,
          createby:     null,
          updatedate:   null,
          updateby:     null,
          deletedate:   null,
          deleteby:     null,
          publishdate:  null
        }
      }

     // cachedImage = this.appData.imageCache.find(img => img.title === pageImages[i].title);

      this.imageLog(`[${i}] ${pageImages[i].title} ${pageImages[i].imgReference} ${pageImages[i].image.length}`);

      if (!pageImages[i].image.length) { // image doesn't exist or is somehow fubar
        if (this.appData.debug) { // add 
          strImage = `<div class="error">'${pageImages[i].title}' not found in database or is missing .image property. </div>`;
        }
        else {// just eat the image
          strImage = "";
        }

        content = content.replace(pageImages[i].imgReference, strImage);
      } else { // image exists
        if (pageImages[i].width === "100%")
          strWidth = "";
        else 
          strWidth = `width=${pageImages[i].width}`

        if (pageImages[i].showPlain) {
          strImage = `<img src="${pageImages[i].image}" ${strWidth} />`; //data:image/png;base64, width="25%"
          content = content.replace(pageImages[i].imgReference, strImage );
        } else if (pageImages[i].showThumbnail) {
          strImage = `<ion-thumbnail><img src="${pageImages[i].image}"/></ion-thumbnail>`; //data:image/png;base64, 
          content = content.replace(pageImages[i].imgReference, strImage );
        } else if (!pageImages[i].showCaption &&  !pageImages[i].showCredit) { // no caption and no credit
          strImage = `<img src="${pageImages[i].image}" ${strWidth}/>`; // data:image/png;base64, 
          content = content.replace(pageImages[i].imgReference, strImage );
        } else {
          strImage = cardPattern;

          if (!pageImages[i].showCaption)
            strImage = strImage.replace("!!caption!!", "&nbsp;");
          else 
            strImage = strImage.replace("!!caption!!", `<ion-card-title>${pageImages[i].caption||""}</ion-card-title>`);

          if (!pageImages[i].showCredit || !pageImages[i].credit )
            strImage = strImage.replace("!!credit!!", "&nbsp;");
          else
            strImage = strImage.replace("!!credit!!", `Credit: ${pageImages[i].credit || '&nbsp;'}`);  

          this.imageLog(`Image pattern: ${strImage}`);
          strImage = strImage.replace("!!img!!", `<img src="${pageImages[i].image || ''}"/>`); // data:image/png;base64, 
          content = content.replace(pageImages[i].imgReference, strImage);
        }
       
      }
    }
    return content;
  }

  private inlineCache = [];

  // remove any inline code from the content before doing any variable substitution. Search for <code>some content</code> and ```other content```
  inlineCodeOut(content: string) {
    let func: string = "inlineCodeOut";
    //this.appData.log(`${this.module}${func}`);

    var exprStart: RegExp;
    var resultStart: any;
    var value: string;
    var repl:  string;
    var count: number = 0;

    this.inlineCache = [];

    exprStart = new RegExp("<code>[\\s\\S]*?</code>", "i"); // match anything between <code> and </code>
    resultStart = exprStart.exec(content); 

    while (resultStart && count < 30) {
      value = resultStart.toString();
      repl = `@@code${count}@@`;
      
      this.inlineCache[count] = { repl: repl, value: value};
      content = content.replace(value, repl);

      count++;
      resultStart = exprStart.exec(content);
    }
    
    exprStart = new RegExp("```[\\s\\S]*?```", "i"); // match anything between ``` and ```
    resultStart = exprStart.exec(content); 

    while (resultStart && count < 30) {
      value = resultStart.toString();
      repl = `@@code${count}@@`;
      
      this.inlineCache[count] = { repl: repl, value: value};
      content = content.replace(value, repl);

      count++;
      resultStart = exprStart.exec(content);
    }

    
    return content;
  }

  // Put inline code back in after all the other substitutions are complete.
  inlineCodeIn(content: string) {
    for (var i = 0; i < this.inlineCache.length; i++) {
      content = content.replace(this.inlineCache[i].repl, this.inlineCache[i].value);
    }
    return content;
  }

  isSubstitutionError(content: string) {
    let func: string = 'isSubstitutionError';
    //this.log(`${this.module}${func}`);
    
    //substitute the aircraft and links data
    content = this.aircraftValues(content);
    content = this.links(content);

    var offset: number = 0;
    var start: number, end: number, fullname: string, cTitle: string;
    var myImg: string;
    //let myImages = [];
    let index: number = 0;

    while ( (start = content.indexOf("%%img", offset)) >= 0) {
        end = content.indexOf("%%", start+1);
        fullname = content.slice(start, end+2);
        cTitle  = content.slice(start+6, end)
        //this.log(this.module + "found image reference: '" + fullname + "' " + cTitle);

        myImg = '<fake-img src="{{img' + index + '}}" />' // data:image/png;base64,
    
        content = content.replace(fullname, myImg); // this puts the placeholder string there, but we never get the image.    
        
        offset = end; // setup for the next loop iteration
        index ++;
    }
    //this.log(content);
    //this.log(`Error: ${content.indexOf("%%") >= 0 }`);

    if (content.indexOf("%%") >= 0 || content.indexOf("(Page Not Found)") > 0) {
      return true; // %% still exists or image wasn't found => error
    }
    return false;
  }
}
