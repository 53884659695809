import { enableProdMode, Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import { environment } from '../../environments/environment';

import { CrudService } from '../services/crud.service';
import { DatabaseService } from '../services/database.service';

import { Article } from '../classes/article';
import { Airport } from '../classes/airport';
import { Lookup } from '../classes/lookup';
import { Make } from '../classes/make';
import { Model } from '../classes/model';
import { MyAircraft } from '../classes/myaircraft';
import { Asset } from '../classes/asset';
import { Coordinates } from '../classes/geocoords';
import { Quiz } from '../classes/quiz';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Device } from '@capacitor/device';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class AppDataService {
  private module: string = "app-data.service.ts/";
  private logEnabled: boolean = true;
  public article: Article ; 

  public airports:   Airport[] = [];
  public lookups:    Lookup[]  = [];
  public makes:      Make[]    = [];
  public models:     Model[]   = [];
  public imageCache: Asset[]   = []; // Cache images previously loaded (substitution service).
  public myAircraft: MyAircraft[] = []; // myAircraft differs from model because values are specific strings not arrays like models.turbo
  public defaultAircraft = {make: "", model: ""}; // this gets overwritten with a merged structure.
  public username: string = "";
  public quizzes:    Quiz[]    = [];

  public singleChoiceColor: string = "secondary";
  public multiChoiceColor:  string = "tertiary";

  public appInitialized: boolean = false;
  public debug: boolean = false;
  
  // move to config loaded at runtime
  public environ = {
    production  : true,
    isApp       : false,
    appVersion  : "0",
    environment : "",
    token       : "",
    apiUrl      : "https://www.secondincommand.net/api/",
    logEnabled  : true,
    imagepath   : "/assets",
    measurement : "american" as string,
    screenOrientation: "landscape" as string, // mobile
    splashScreenDelay: 2000, // mobile: display the splash screen for 2 seconds at launch
    dataSource: "webservice", // sqlite
    platform:   "web" // ios, android
  }

  public device: any; // returned from @capacitor.Device.getInfo()
  public coordinates = new Coordinates();
  
  constructor(public toastController: ToastController, public alertController: AlertController, public geolocation: Geolocation,
    public crud: CrudService, public databaseService: DatabaseService) 
  { 
    let func: string = "constructor";  
    this.log(`${this.module}${func} fired`);   

    this.environ.production = environment.production;
    this.environ.appVersion = environment.appVersion;
    this.setToken(environment.token);
  }

  async initialize(platform?: string) {
    let func: string = "initialize";  
    let port: string = "";
    let apiName: string = "";

    // for testing
    //environment.production = true;
    //console.log(environment);
    //this.setToken('');
    
    if (window.location.hostname.includes("local")) {
      port = "8080";
      let apiName = `http://adminsite.local.com:${port}/api/`;
      this.environ.apiUrl = apiName;
      this.crud.setBaseUrl(this.environ.apiUrl); 
    } else {
      this.crud.setBaseUrl(this.environ.apiUrl);
    }      
    
    this.log(`${this.module}${func} fired ${apiName}`);
    
    this.log(this.environ);
  }

  setToken(token: string): void {
    //this.environ.token = token;
    this.crud.setToken(token);
  }
  
  setApiUrl(url: string) {
    this.environ.apiUrl = url;
    this.crud.setBaseUrl(url);
  }

  getToken(): string {
    return this.environ.token;
  }

  public metricConversion(value: any) {
    if (isNaN(value))
      return 0;
    if (this.environ.measurement === 'american')
      return value * 3.2808;
    return value; // GPS values are metric
  }
  

  log(message:any) {
    if (this.logEnabled) {
      // log and info look the same. Warn is yellow. Error is red. 
      console.log(message);
      //console.info(message);
      //console.warn(message);
      //console.error(message);

      //console.debug(message); .debug does nothing.
    }
  }
  error(message:any) {
    console.error(message);
  }

  loadData() {

    this.loadLookups();
    this.loadMakes();
    //this.loadModels();  // this is called after makes are loaded so we can map model.makeid to make._id
    
    //this.loadAirports(); // not doing anything with airports yet. seems silly to load 19K+ records each time app loads.
    this.loadQuizzes();
  }

  loadLookups(sqlite?: boolean) {
    let func = "loadLookups";
    this.log(`${this.module}${func} fired.`);

    if (sqlite) {

    } else {
      var x = this.crud.getAll('lookups').subscribe(results=> {
        this.lookups = results;
        this.log(`${this.module}${func} loaded lookups: ${this.lookups.length} records`);
      }, error => {
        this.error(`${this.module}${func} error`);
        this.error(error);
      });
    }
  }

  loadMakes(sqlite?: boolean) {
    let func = "loadMakes";
    this.log(`${this.module}${func} fired.`);
    
    if (sqlite) {
      let sqlcmd = "SELECT * FROM makes WHERE status = 'A'";
      
      this.databaseService.runQuery(sqlcmd, []).subscribe((results) => {
        //console.log(`${this.module}${func} results:`);
        //console.log(results);
          this.makes = results;
          this.loadModels(true);
        }, error => {
          let errorMessage = error.errorMesage;
          this.error(this.module + func + "error");
          this.error(error);
        });
    
    } else {  
      var x = this.crud.getAll('makes').subscribe(results=> {
        this.makes = results;
        this.loadModels(false);
        this.log(`${this.module}${func} loaded makes: ${this.makes.length} records`);
      }, error => {
        this.error(`${this.module}${func} error`);
        this.error(error);
      });
    }
  }

  loadModels(sqlite?: boolean) {
    let func = "loadModels";
    this.log(`${this.module}${func} fired.`);

    if (sqlite) {
      let sqlcmd = "SELECT * FROM models WHERE status = 'A'";
      
      this.databaseService.runQuery(sqlcmd, []).subscribe((results) => {
          this.models = results;
          this.updateModelStructure();
          this.loadMyAircraft(true);
        }, error => {
          let errorMessage = error.errorMesage;
          this.error(this.module + func + "error");
          this.error(error);
        });
      
    } else {
    var x = this.crud.getAll('models').subscribe(results=> {
      this.models = results;
      this.log(`${this.module}${func} returned ${results.length} models.`);

      this.updateModelStructure();
        this.loadMyAircraft(false);

      }, error => {
        this.error(`${this.module}${func} error`);
        this.error(error);
      });
    }
  }

  updateModelStructure() {
    let func = "updateModelStructure";
    let findMake: Make;
      // update model structure.
      this.models.forEach((model) => {
        // added after the database was populated. fix with: db.models.updateMany({},{$set: {"airframeparachute":["no"]}});
        if(!model.hasOwnProperty("airframeparachute") || !model.airframeparachute.length )
          model.airframeparachute = ["no"];
        if(!model.hasOwnProperty("autoland") || !model.autoland.length )
          model.autoland = ["no"];

        // add the makeid to the model if it doesn't exist
        if (!model.hasOwnProperty("makeid") || model.makeid === undefined) {
          findMake = this.makes.find( ({ make }) =>  make === model.make );

          model.makeid = findMake._id || "-1"; // models without a matching make
        }
      });
      if (this.environ.isApp )
      this.loadMyAircraft();
  }

  loadMyAircraft(sqlite?: boolean) {
    let func = "loadMyAircraft";
    this.log(`${this.module}${func} fired.`);

    if (sqlite) {
      let sqlcmd = "SELECT * FROM myaircraft WHERE status = 'A'";
      
      this.databaseService.runQuery(sqlcmd, []).subscribe((results) => {
          this.myAircraft = results;
          this.myAircraftAfterLoad();
        }, error => {
          let errorMessage = error.errorMesage;
          this.error(this.module + func + "error");
          this.error(error);
        });
    } else {
      var x = this.crud.getAll('myaircraft').subscribe(results=> {
        this.myAircraft = results;
        this.myAircraftAfterLoad();
      }, error => {
        this.error(`${this.module}${func} error`);
        this.error(error);
      });
    }
  }

  myAircraftAfterLoad() {
    let func = "myAircraftAfterLoad";
      this.log(`${this.module}${func} loaded myAircraft: ${this.myAircraft.length} records`);
      if (this.myAircraft.length == 0) {
        this.createSystemDefaultAircraft(); 
      }

      // make sure there is a default aircraft set so substitution works.
      var default_id: number  = -1;
      for (var i = 0; i < this.myAircraft.length; i++) {
        if (this.myAircraft[i].default === true)
          default_id = i;
      }
      if (default_id < 0)
        this.myAircraft[0].default = true;

      this.setDefaultAircraft();
  }

  createSystemDefaultAircraft() {
    this.myAircraft[0] = new MyAircraft();

    this.myAircraft[0]._id =  "-1";
    this.myAircraft[0].make =  "Cessna";
    this.myAircraft[0].model = '172 Fixed Pitch Prop';
    this.myAircraft[0].model_id = "0"; // this is bad because it could be different
    this.myAircraft[0].tailnumber = "N12345";
    this.myAircraft[0].name = "Default Aircraft";

    this.myAircraft[0].default = true;
  }

  setDefaultAircraft() {
    let func = "setDefaultAircraft";
    //this.log(`${this.module}${func} called`);
    
    this.myAircraft.forEach((aircraft) => {
      if (aircraft.default === true) {
        this.log(`${this.module}${func} default is '${aircraft.make}' '${aircraft.model}'`)
        this.models.forEach((model) => {
          if (aircraft.model_id !== "0" && aircraft.model_id === model._id) {
            this.log(`${this.module}${func} merging by ID model-${model.model} and myaircraft-${aircraft.model}`);
            Object.assign(this.defaultAircraft, model, aircraft);
            
          } else if (aircraft.make === model.make && aircraft.model === model.model) {
            this.log(`${this.module}${func} merging by name model-${model.model} and myaircraft-${aircraft.model}`);
            Object.assign(this.defaultAircraft, model, aircraft);
          }
        });
      }
    });

    //this.log(this.defaultAircraft);
  }

  loadAirports(sqlite?: boolean) {
    let func = "loadAirports";
    this.log(`${this.module}${func} fired.`);

    if (sqlite) {
      //let sqlcmd = "SELECT some fields FROM airports WHERE status = 'A'";
      //this.databaseService.runQuery(sqlcmd, []).subscribe((results) => {
      //}
    } else {
      var x = this.crud.getAll('airports').subscribe(results=> {
        this.airports = results;
        this.log(`${this.module}${func} loaded airports: ${this.airports.length} records`);
      }, error => {
        this.error(`${this.module}${func} error`);
        this.error(error);
      });
    }
  }

  loadQuizzes(sqlite?: boolean) {
    let func = "loadQuizzes";
    this.log(`${this.module}${func} fired.`);

    if (sqlite) {
      //let sqlcmd = "SELECT * FROM quizzes WHERE status = 'A'";
      //this.databaseService.runQuery(sqlcmd, []).subscribe((results) => {
      //}
    } else {
      var x = this.crud.getAll('quizzes').subscribe(results=> {
        this.quizzes = results;
        this.log(`${this.module}${func} loaded quizzes: ${this.quizzes.length} records`);
        console.log(this.quizzes);
      }, error => {
        this.error(`${this.module}${func} error`);
        this.error(error);
      });
    }
  }

  logout() {
    console.log('appData.logout');
    this.appInitialized = false;
    /*
    this.makes = [];
    this.models = [];
    this.myAircraft = [];
    this.lookups = [];
    this.appInitialized = false;
    */
  }

  // Examples:
  // this.appData.presentToast("Please enter all the required values", 1500);
  async presentToast(message: string, duration: number = 1500, color: string = "danger") {
    const toast = await this.toastController.create({
      message: message,
      duration: duration, 
      color: color,
      position: "top"
    });
    toast.present();
  }

  // modal requiring response
  async presentAlertWithOptions(title: string, message: string, buttonArray: any) {
      const alert = await this.alertController.create({
      header: title,
      message: message,
      buttons: buttonArray
    });
    alert.present();
  }

  public filterLookups(searchTerm: string) {
    return this.lookups.filter((item) => {  
        return item.type.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;
    });    
  }
  public filterModels(makeFilter: string) {
    /*
    return this.models.filter((model) => { 
        return model.make.toLowerCase().indexOf(make.toLowerCase()) > -1;
    });
    */
    return this.models.filter( model => model.make === makeFilter);    
  }
  

  public filterAirports(field: string, searchTerm: string) {
    let func: string = "filterAirports";
    return this.airports.filter((airport) => {
      switch(field) {
        case "City": 
          return airport.City === searchTerm;
        case "State": 
          return airport.State === searchTerm;
        case "LocationID": 
          return airport.LocationID === searchTerm;
        default:
          this.log(`${this.module}${func}  ${field} - filter not defined - update the code`);
          break;
      }
    });
  }

  sortMakes(makes: Make[]) {
    let func: string = "sortMakes";
    this.log(`${this.module}${func}`);

    this.makes.sort(function(a, b) {
      var nameA = a.make.toUpperCase(); // ignore upper and lowercase
      var nameB = b.make.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // values must be equal
      return 0;
    });
    return makes;
  }

  sortModels(models: Model[]) {
    let func: string = "sortModels";
    this.log(`${this.module}${func}`);

    models.sort(function(a, b) {
      var nameA = a.model.toUpperCase(); // ignore upper and lowercase
      var nameB = b.model.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      // values must be equal
      return 0;
    });
    return models;
  }
  
  async getCurrentPosition() {
    this.geolocation.getCurrentPosition().then((res) => {
      console.log('Current position:');
      console.log(res);  
      this.coordinates = res.coords;
    }).catch((err) => {
      console.log(`geoError: ${JSON.stringify(err)}`)
    })    
  };

}
