import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError, retry } from 'rxjs/operators';

import { AppDataService } from './app-data.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  module = "authentication.ts "; 
  public authenticated: boolean = false; // set to true to skip login for development. should be in appData.environ.
  public username: string = "";

  constructor(public appData: AppDataService, public http: HttpClient) { 
    if (this.appData.environ.production === false)
      this.authenticated = true; // overrride for dev so login isn't required on every reload

  }

  isAuthenticated() {
    return  this.authenticated;
  }

  setAuthenticated(status: boolean) {
    this.authenticated = status;
  }
   
  logout() {
    this.authenticated = false;
    this.appData.setToken("");
    this.appData.logout();
  }

   authenticate(data: any): Observable<any> {
    let func: string = "authenticate";
    let body = JSON.stringify(data);
    console.log(`${func} environ.apiUrl ${this.appData.environ.apiUrl}`);
    let apiName = this.appData.environ.apiUrl.replace("/api/", "/public/authentication/").replace("admin.", "www.");

    console.log(`${func} A ${apiName}`);
    if (apiName.includes("https")) {
      apiName = "https://www.secondincommand.net/public/authentication/";
      console.log(`${func} X ${apiName}`);
      //this.appData.setApiUrl(apiName.replace("/public/authentication/", "/api/"));
      //console.log(this.appData.environ.apiUrl);
    }

    
    //let finalURL = url + apiName;
    var headers = new HttpHeaders();
    headers.append('Accept', 'application/json');
    headers.append('token', "");
    //return this._http.get<T[]>(this._base).pipe(catchError(this.handleError));
   return this.http.post(apiName, data, { headers: headers }).pipe(catchError(this.handleError));
  }

  handleError(error: Response | any) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    
    return throwError(errorMessage);
  }
}
