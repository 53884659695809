
export class MyAircraft {
    _id:            string;
    make:           string;
    model:          string;
    model_id:       string; // get details from the model
    tailnumber:     string;
    name:           string;
    //year:           number;

    // These fields in Model could have options depending on the aircraft.
    enginetype:     string;
    turbo:          string;
    flaps:          string;
    flapsetting:    string;
    propellertype:  string;
    wheellocation:  string;
    airframeparachute: string;
    autoland:       string;

    // Not aircraft specific - these are copied from Model
    cruise:         string;
    approach:       string;
    climb:          string;
    vrefknots:      number;
    minrunway:      number;
    descent:        string;
    numengines:     string;
    landinggear:    string;
    flightcontrols: string;

    default:        boolean;

    status:         string;
    createdate:     Date;
    updatedate:     Date

    
    constructor() {
        this._id =               "0";
        this.make =              "";
        this.model =             "";
        this.model_id =          ""; // get details from the model
        this.tailnumber =        "";
        this.name =              "";
        //year =            0;
    
        this.enginetype =        "piston";
        this.turbo =             "none";
        this.flaps =             "handle";
        this.flapsetting =       "";
        this.propellertype =     "fixed pitch";
        this.wheellocation =     "";
        this.airframeparachute = "no";
        this.autoland =          "no";
           
        this.cruise =            "";
        this.approach =          ""; 
        this.climb =             "";
        this.vrefknots =         0;
        this.minrunway =         0;
        this.descent =           "";
        this.numengines =        "single";
        this.landinggear =       "nose wheel";
        this.flightcontrols =    "yoke";

        this.default =           false;
        this.status =            "A";
        this.createdate =        null;
        this.updatedate =        null
    }
}